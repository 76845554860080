require('./bootstrap');

let lazyLoadInstance = new LazyLoad({
  callback_error: (img) => {
    img.setAttribute("src", '/images/_blank.png');
  }
});
cssVars({
// Options...
});

/**
 *
 * @param el
 */
function hideMenuScrollBottom(el) {
  if (el.width() <= 768) {
    if (el.scrollTop() > 0 && el.scrollTop() + el.height() >= $(document).height() - 70) {
      $('.my-page .sidebar .fix-block').addClass('hide');
    } else {
      $('.my-page .sidebar .fix-block').removeClass('hide');
    }
  }
}

/**
 * Fix bottom when scroll down
 * @param el
 */
function scrollFixTopBar(el) {
  if (el.width() <= 768) {
    if (el.scrollTop() >= 20) {
      const h = $('.top-navbar').outerHeight();
      $('body').css({'padding-top': h+'px'})
      $('.top-navbar').addClass('fixed');
    } else {
      $('body').css({'padding-top': '0px'})
      $('.top-navbar').removeClass('fixed');
    }
  } else {
    $('.top-navbar').removeClass('fixed');
    $('body').css({'padding-top': '0px'})
  }
}

/**
 * Init custom file
 */
function initHandleCustomSelectFile () {
  $(document).on('change', '.custom-file-dexpo .custom-file-input', function (e) {
    let fileName = $(this).val().split("\\").pop();
    $(this).siblings(".custom-file-label").addClass("selected").html(fileName);

    const previewEl = $(this).data('preview') || null;
    if (previewEl && $(this).parents('.custom-file-dexpo').find('.' + previewEl).length > 0) {
      const [file] = this.files;
      if (file) {
        $('.' + previewEl + ' img').attr('src', URL.createObjectURL(file))
      } else {
        $('.' + previewEl + ' img').attr('src', '')
      }
    }
  });
};

function addLoadErrorImage() {
  const img = $('body').find('img');
  img.on('error', function (e) {
    $(this).addClass('error-d')
  })
}

$(function () {
  hideMenuScrollBottom($(window));
  scrollFixTopBar($(window, document));
  initHandleCustomSelectFile();
  addLoadErrorImage();

  $(window).on("scroll", function () {
    hideMenuScrollBottom($(this));
    scrollFixTopBar($(this));
  });

  $(window).on("resize", function () {
    hideMenuScrollBottom($(this));
    scrollFixTopBar($(this));
  });
})

